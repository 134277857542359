import React, { PropsWithChildren, ReactNode } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import Link from '@components/common/Link';
import ProductFullResponsiveData from '@components/common/ProductFullResponsive/ProductData';
import { PageTypes } from '@constants/pageTypes';
import { IProductInList } from '@interfaces/product';
import { isProductNotAvailable } from '@utils/product';

import styles from './styles';

interface IProductFullProps {
  item: IProductInList;
  productUrl?: string;
  productId?: number;
  rightAction?: ReactNode;
  sizeId?: number;
  withLike?: boolean;
}

const ProductFullResponsive: React.FunctionComponent<IProductFullProps & PropsWithChildren> = ({
  children,
  item,
  productId,
  rightAction,
  withLike = true,
  sizeId,
  productUrl,
}) => {
  const classes = styles();

  const isNotAvailable = isProductNotAvailable(item);

  return (
    <ErrorBoundary>
      <div className={classes.productFull}>
        {productUrl ? (
          <Link href={productUrl} pageType={PageTypes.productDetail} className={classes.productLink}>
              <ProductFullResponsiveData item={item} productId={productId} rightAction={rightAction} sizeId={sizeId} withLike={withLike} />
          </Link>
        ) : <ProductFullResponsiveData item={item} productId={productId} rightAction={rightAction} sizeId={sizeId} withLike={withLike} />}
        {!isNotAvailable && children}
      </div>
    </ErrorBoundary>
  );
};

export default ProductFullResponsive;
