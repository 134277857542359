import React from 'react';

const LikeIconSprite: React.FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} width="20" height="18" viewBox="0 0 20 18" fill="none">
      <use href="#likeActiveSprite" />
    </svg>
  );
};
export default LikeIconSprite;
