import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import { usePriceData } from '@hooks/usePriceData';
import { useTranslation } from '@hooks/useTranslation';
import { IProductColor, IProductColorMini, IProductInList } from '@interfaces/product';

import styles from './styles';

interface IProductFullProps {
  item: IProductInList;
  productId?: number;
  toCol?: boolean;
}

const ProductFullResponsiveDataPrice: React.FunctionComponent<IProductFullProps> = ({
  item,
  productId,
  toCol = false,
}) => {
  const classes = styles();
  const { t } = useTranslation();
  const findCurrentColor = (color: IProductColor | IProductColorMini) => String(color.productId) === String(productId);
  const currentColor: IProductColor | undefined = productId ? item.colors.find(findCurrentColor) : item.colors[0];
  const {
    currencyValue,
    discountPrice,
    price,
    withDiscount,
    withDiscountCustomerOnly,
  } = usePriceData(currentColor?.price);

  return (
    <ErrorBoundary>
      <div className={classNames(classes.productFullPrice, { [classes.productFullPriceCol]: toCol })}>
        <div className={classNames(classes.productFullPriceValue, { [classes.withDiscount]: withDiscount })}>
          {price || <Skeleton animation={false} width={'40px'} />}
          {currencyValue}
        </div>
        {withDiscount && (
          <div className={classNames(classes.productDiscountPrice)}>
            {discountPrice}
            {currencyValue}
          </div>
        )}

      </div>
      {withDiscountCustomerOnly && (
        <div className={classNames(classes.productDiscountCustomerOnlyPrice)}>
          {t('product.priceForRegistered')}: <br />
          <span>{discountPrice}{currencyValue}</span>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default ProductFullResponsiveDataPrice;
