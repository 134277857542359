import React from 'react';

import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

interface IColorRoundProps {
  color: { code?: string [], image?: string };
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    colorRound: {
      borderRadius: '50%',
      boxShadow: '0 0 0 1px ' + ColorsPalette.neutral1,
      height: '8px',
      marginLeft: '10px',
      width: '8px',
    },
  }));

const ColorRound: React.FunctionComponent<IColorRoundProps> = ({ color }) => {
  const classes = useStyles();
  const generateBackground = (colors: string[]): string => {
    switch (colors.length) {
      case 1:
        return `#${colors[0]}`;
      case 2:
        return `linear-gradient(131deg, ${colors[0]} 0%, ${colors[1]} 100%)`;
      case 3:
        return `linear-gradient(131deg, ${colors[0]} 0%, ${colors[1]} 50%, ${colors[2]} 100%)`;
      default:
        return `#${colors[0]}`;
    }
  };
  if (color.image) {
    return <div className={classes.colorRound} style={{ backgroundImage: `url(${color.image})` }} />;
  } else if (color.code) {
    return <div className={classes.colorRound} style={{ background: generateBackground(color.code) }} />;
  } else {
    return null;
  }
};


export default ColorRound;
