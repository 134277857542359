import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';

import PictureShow from '@components/common/PictureShow';
import ProductImageAvailability from '@components/common/ProductFullResponsive/ProductImage/Availability';
import ProductImageLike from '@components/common/ProductFullResponsive/ProductImage/Like';
import ProductImageTopActions from '@components/common/ProductFullResponsive/ProductImage/TopActions';
import { IProductInList } from '@interfaces/product';

import itemStyles from './../styles';

interface IProductImageProps {
  isNotAvailable?: boolean;
  product: IProductInList;
  productId?: number;
  rightAction?: ReactNode;
  sizeId?: number;
  withLike?: boolean;
}

const ProductImage: React.FunctionComponent<IProductImageProps> = ({
  isNotAvailable,
  product,
  productId,
  rightAction,
  sizeId,
  withLike = true,
}) => {
  const classesItem = itemStyles();

  const [hoverImage, handleHoverImage] = useState(false);
  const [hoveredImage, handleHoveredImage] = useState(false);
  const [pictureLoaded, changePictureLoaded] = useState(false);
  const [pictureSecondLoaded, changePictureSecondLoaded] = useState(false);

  const currentProductId = productId || product.colors[0]?.productId;
  const currentColor =
    product.colors.find((colorItem) => String(colorItem.productId) === String(currentProductId)) || product.colors[0];
  const imageFirst = currentColor?.pictures[0]?.urlMedium;
  const imageSecond = currentColor?.pictures[1] ? currentColor?.pictures[1].urlMedium : imageFirst;

  return (
    <div
      className={classesItem.productFullImage}
      onMouseEnter={() => {
        handleHoverImage(true);
        if (!hoveredImage) {
          handleHoveredImage(true);
        }
      }}
      onMouseLeave={() => handleHoverImage(false)}
    >
      <PictureShow
        onLoaded={() => changePictureLoaded(true)}
        className={classNames(classesItem.productFullImageImg, {
          [classesItem.productFullImageImgHide]: hoverImage && pictureSecondLoaded,
        })}
        src={imageFirst}
      />
      {hoveredImage && (
        <PictureShow
          onLoaded={() => changePictureSecondLoaded(true)}
          className={classNames(classesItem.productFullImageImg, {
            [classesItem.productFullImageImgHide]: !hoverImage || !pictureSecondLoaded,
          })}
          src={imageSecond}
        />
      )}
      {withLike && <ProductImageLike currentProductId={currentProductId} product={product} color={currentColor} />}
      <ProductImageAvailability
        pictureLoaded={pictureLoaded}
        currentProductId={currentProductId}
        product={product}
        isNotAvailable={isNotAvailable}
        sizeId={sizeId}
      />
      <ProductImageTopActions product={product} currentProductId={currentProductId} rightAction={rightAction} />
    </div>
  );
};

export default ProductImage;
