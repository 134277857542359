import { useSelector } from 'react-redux';

import { currency } from '@constants/currency';
import { IProductPrice } from '@interfaces/product';
import { IStore } from '@interfaces/store';

interface IUsePriceDataResult {
  currencyValue: string;
  withDiscount: boolean;
  withDiscountCustomerOnly: boolean;
  discountPrice: string;
  price: string;
}

export const usePriceData = (price: IProductPrice | undefined): IUsePriceDataResult => {

  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);

  const discountPrice = price?.priceDiscount || '';
  const priceDiscountCustomerOnly = price?.priceDiscountCustomerOnly || '';

  const productWithDiscount = parseFloat(discountPrice || '0') > 0;
  const productWithDiscountCustomerOnly = !productWithDiscount && parseFloat(priceDiscountCustomerOnly || '0') > 0;

  const currencyName = price?.currencyName || '';
  const currencyValue = currencyName ? currency[currencyName] : currency.EUR;

  return {
    currencyValue,
    discountPrice: productWithDiscount ? discountPrice : priceDiscountCustomerOnly,
    price: price?.price || '',
    withDiscount: productWithDiscount || (isAuthorized && productWithDiscountCustomerOnly),
    withDiscountCustomerOnly: !isAuthorized && !productWithDiscount && productWithDiscountCustomerOnly,
  };
};
