import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acWishListAddAction, acWishListRemoveAction } from '@actions/acWishList';
import { EUserAgent } from '@constants/app';
import LikeIconSprite from '@icons/LikeActiveSprite';
import LikeSprite from '@icons/LikeSprite';
import LoadingIcon from '@icons/Loading';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { getProductData } from '@utils/gtag';

import { IProductColor, IProductInList } from '@interfaces/product';
import imageStyles from './styles';

interface IProductImageLikeProps {
  product: IProductInList;
  color: IProductColor;
  currentProductId: number;
  atTop?: boolean;
}

const ProductImageLike: React.FunctionComponent<IProductImageLikeProps> = ({
  atTop = false,
  currentProductId,
  product,
  color
}) => {
  const classesImage = imageStyles();
  const dispatch = useDispatch<TThunkDispatch>();

  const [mouseActive, changeMouseActive] = useState(false);
  const [likeLoading, changeLikeLoading] = useState(false);

  const userAgentType = useSelector<IStore, EUserAgent | null>((state: IStore) => state.context.userAgentType);
  const wishListId = useSelector<IStore, number[]>((state: IStore) => state.wishList.listId);
  const onAddToWishList = useCallback<(productToAdd, productData) => Promise<any>>((productToAdd, productData) => dispatch(acWishListAddAction({ productId: productToAdd }, {productData})), [dispatch]);
  const onRemoveWishList = useCallback<(productToRemove) => Promise<any>>((productToRemove) => dispatch(acWishListRemoveAction(productToRemove)), [dispatch]);

  const isLiked = wishListId?.find((listItem) => Number(listItem) === Number(currentProductId));

  const changeLike = () => {
    if (currentProductId) {
      changeLikeLoading(true);
      const productData = getProductData(product, color);
      !isLiked
        ? onAddToWishList(currentProductId, productData).then(() => {
          changeLikeLoading(false);
       //   addToWishlist(userId, productData);
        })
        : onRemoveWishList(currentProductId).then(() => {
          changeLikeLoading(false);
        });
    }
  };

  return (
    <div
      className={classNames(classesImage.likeIconWrap,
        {
          [classesImage.likeIconBottom]: !atTop,
          [classesImage.likeIconTop]: atTop,
        })}
         data-action="add_to_wishlist"
         onMouseDown={() => changeMouseActive(true)}
         onMouseUp={() => {
           changeMouseActive(false);
           changeLike();
         }}
         onMouseOut={() => changeMouseActive(false)}
         onClick={(e) => {
           e.preventDefault();
           e.stopPropagation();
           changeLike();
         }}
    >
    <div
      className={classNames(classesImage.likeIcon,
        {
          [classesImage.likeIconBlur]: userAgentType !== EUserAgent.android,
          [classesImage.likeIconBottom]: !atTop,
          [classesImage.likeIconTop]: atTop,
        })}
    >
      {likeLoading ? <LoadingIcon /> : mouseActive || isLiked ? <LikeIconSprite /> : <LikeSprite />}
    </div>
    </div>
  );
};

export default ProductImageLike;
