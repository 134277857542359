import React from 'react';

const LikeIconSprite: React.FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} width="21" height="20" fill="none" viewBox="0 0 21 20">
      <use href="#likeSprite" />
    </svg>
  );
};

export default LikeIconSprite;
