import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import ErrorBoundary from '@components/common/ErrorBoundary';
import { EUserAgent } from '@constants/app';
import { thresholdStockLow } from '@constants/index';
import { useTranslation } from '@hooks/useTranslation';
import { IProductInList, IProductSize } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import { isProductGiftCard } from '@utils/product';

import imageStyles from './styles';

interface IProductImageAvailabilityProps {
  isNotAvailable?: boolean;
  product: IProductInList;
  currentProductId: number;
  pictureLoaded: boolean;
  sizeId?: number;
}

const ProductImageAvailability: React.FunctionComponent<IProductImageAvailabilityProps> = ({
  isNotAvailable,
  product,
  currentProductId,
  pictureLoaded,
  sizeId,
}) => {
  const classesImage = imageStyles();
  const { t } = useTranslation();
  const userAgentType = useSelector<IStore, EUserAgent | null>((state: IStore) => state.context.userAgentType);

  const isGiftCard: boolean = isProductGiftCard(product);
  const currentColor = product.colors.find((colorItem) => String(colorItem.productId) === String(currentProductId)) || product.colors[0];

  const stockQty = product?.colors?.map((color) => color.sizes?.map((size) => size.stockQty)?.flat())?.flat();
  const currentSize: IProductSize | null = sizeId !== undefined ? currentColor.sizes.find((size) => size.sizeId === sizeId) || null : null;
  const maxStockValue = currentSize ? currentSize.stockQty : Math.max(...stockQty);

  return (
    <ErrorBoundary>
      {(!isGiftCard && pictureLoaded && (maxStockValue < thresholdStockLow) || isNotAvailable) && (
        <div className={classNames(classesImage.stockStatus, {[classesImage.stockStatusBlur]: userAgentType !== EUserAgent.android})}>
          {maxStockValue < 1 || isNotAvailable ? t('cart.product_qty_out') : t('cart.product_qty_available', { qty: maxStockValue })}
        </div>)}
    </ErrorBoundary>
  );
};

export default ProductImageAvailability;
