import React from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import { IProductInList } from '@interfaces/product';

interface IProductFullProps {
  item: IProductInList;
}

const ProductFullResponsiveDataSizes: React.FunctionComponent<IProductFullProps> = ({ item }) => {

  const sizes = item.colors.map((color) => color.sizes?.map((size) => size.size)).flat() || [];
  const mappedSizes = sizes.map((size) => {
    return size?.replace(/\/.*/, '');
  });
  const uniqSizes = mappedSizes.filter((size, index) => mappedSizes.findIndex((sizeName) => sizeName === size) === index);
  const uniqSizesLength = uniqSizes.length;

  return (
    <ErrorBoundary>
      {uniqSizesLength > 3 ? `${uniqSizes.slice(0, 3).join(' ')} +${uniqSizesLength - 3}` : uniqSizes.join(' ')}
    </ErrorBoundary>
  );
};

export default ProductFullResponsiveDataSizes;
