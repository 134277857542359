import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';
import { TypographyCollection } from '@themes/typography';

export default makeStyles<Theme>((theme) => ({
  banner: {},
  deliveryInfo: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0.75rem',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
    ...{
      color: ColorsPalette.secondary,
    },
    ...TypographyCollection.body.bold.s,
  },
  discount: {
    '&$outletPrice': {
      color: ColorsPalette.outletDark,
    },
    ...TypographyCollection.link.bold.l,
    color: ColorsPalette.salesDark,
  },
  discountRegisteredOnly: {
    '& span': {
      color: ColorsPalette.salesDark,
      fontSize: '16px',
    },
    ...TypographyCollection.link.bold.l,
    color: ColorsPalette.primary,
    fontSize: '12px',
    marginTop: 4,
  },
  outletPrice: {},
  price: {
    ...{
      '&$price30Days': {
        fontWeight: '600 !important',
      },
      '&$withDiscount': {
        fontWeight: 'normal',
        textDecoration: 'line-through',
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: '0.75rem',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
      color: ColorsPalette.primary,
    },
    ...TypographyCollection.link.bold.l,
  },
  price30Days: {
    color: ColorsPalette.primary,
    fontSize: '12px',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceDelivery: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'right',
    },
    display: 'flex',
    marginTop: '0.5rem',
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 9,
      marginTop: 64,
      ...TypographyCollection?.heading?.desktop?.m,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...TypographyCollection?.heading?.mobile?.m,
    },
    ...{
      display: 'flex',
      flexDirection: 'column',
    },

    '&$banner': {
      marginTop: 0,
    },
  },
  titleArticle: {
    [theme.breakpoints.up('md')]: TypographyCollection?.heading?.desktop?.m,
    [theme.breakpoints.down('md')]: {
      ...TypographyCollection?.heading?.mobile?.m,
    },
    ...{
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
  },
  titleBrand: {
    [theme.breakpoints.up('md')]: {
      ...TypographyCollection?.heading?.desktop?.m,
    },
    [theme.breakpoints.down('md')]: {
      ...TypographyCollection?.heading?.mobile?.m,
    },
    ...{
      display: 'flex',
      flexDirection: 'column',
      marginBlockEnd: 0,
      marginBlockStart: 0,
    },
  },
  titleModel: {
    [theme.breakpoints.up('md')]: {
      ...TypographyCollection?.heading?.desktop?.xs,
      fontFamily: FontCollection.raleway,
    },
    [theme.breakpoints.down('md')]: {
      '&$titleModelLong': {
        ...TypographyCollection?.heading?.mobile?.s,
      },
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      ...TypographyCollection?.heading?.mobile?.m,
      fontFamily: FontCollection.raleway,
    },
  },
  titleModelLong: {},
  titlePriceContainer: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'stretch',
      justifyContent: 'space-between',
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5rem',
    width: '100%',
  },
  withDiscount: {},
  withDiscountContainer: {
    alignItems: 'center',
  },
}));
