import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { currency } from '@constants/currency';
import { useTranslation } from '@hooks/useTranslation';
import { ISiteSettings } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import Skeleton from '@mui/material/Skeleton';

import detailAreaTitlePriceContainerStyles from './styles';

const Price30Days = ({ color, isList }: { color?: any, isList?: boolean }) => {
  const { t } = useTranslation();
  const classes = detailAreaTitlePriceContainerStyles();
  let currentColor = color;
  const activeProduct = useSelector((state: IStore) => state.productDetails.activeProduct);
  if (currentColor === undefined) {
    currentColor = activeProduct?.color;
  }
  const enable30dayPrice = useSelector<IStore, ISiteSettings['enable30dayPrice'] | undefined>((state: IStore) => state.pageData.data.siteSettings?.enable30dayPrice);
  const discountPrice = Number(currentColor?.price.priceDiscount);
  const withDiscountPrice = discountPrice > 0;
  const currencyName = currentColor?.price?.currencyName || '';
  const priceMinOf30Days = currentColor?.price.priceMinOf30Days;
  const currencyValue = currencyName ? currency[currencyName] : currency.EUR;
  const has30DaysPrice = !isNaN(Number(priceMinOf30Days));
  const isOutlet = !!currentColor?.outlet;
  const isDiscountPriceEqual = Number(discountPrice) === Number(priceMinOf30Days);
  const showIfOutletOr30dayPriceEnabled = isOutlet || enable30dayPrice;

  if (!has30DaysPrice || !withDiscountPrice || isDiscountPriceEqual || !showIfOutletOr30dayPriceEnabled) {
    return null;
  }

  return (
    <div>
      <span className={classes.price30Days}>{t('product.30_days_cheapest_price')}: </span>
      <span className={classNames(classes.price, classes.price30Days)}>
                {priceMinOf30Days || <Skeleton animation={false} width={'40px'} />}
        {currencyValue || <Skeleton animation={false} width={'30px'} />}
            </span>
    </div>
  );
};

export default Price30Days;
