import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles((theme) =>
  createStyles({
    discountTag: {},
    freshTag: {},
    outletPrice: {},
    outletTag: {},
    productDiscountCustomerOnlyPrice: {
      '& span': {
        color: ColorsPalette.salesDark,
        fontSize: '16px',
        fontWeight: '800',
        lineHeight: '136%',
      },
      color: ColorsPalette.primary,
      fontFamily: FontCollection.raleway,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '124%',
      marginBottom: 4,
      marginTop: -4,
    },
    productDiscountPrice: {
      '&$outletPrice': {
        color: ColorsPalette.outletDark,
      },
      color: ColorsPalette.salesDark,
      fontFamily: FontCollection.raleway,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '124%',
    },
    productFull: {
      display: 'flex',
      flex: '1 1 0',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
    },
    productFullCases: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: '22px',
    },
    productFullImage: {
      '&:before': {
        content: '""',
        display: 'block',
        paddingTop: '152%',
        position: 'relative',
        width: '100%',
      },
      backgroundColor: '#eee',
      display: 'block',
      marginBottom: '12px',
      position: 'relative',
      width: '100%',
    },
    productFullImageImg: {
      height: '100%',
      position: 'absolute !important' as 'absolute',
      top: 0,
      width: '100%',
    },
    productFullImageImgHide: {
      visibility: 'hidden',
    },
    productFullNameItem: {
      color: ColorsPalette.primary,
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      height: '18px',
      lineHeight: '132%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    productFullPrice: {
      '&$productFullPriceCol': {
        flexDirection: 'column',
      },
      color: ColorsPalette.primary,
      display: 'flex',
      flexDirection: 'row',
      fontFamily: FontCollection.raleway,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '124%',
      marginBottom: '8px',
      marginTop: '4px',
    },
    productFullPriceCol: {},
    productFullPriceValue: {
      '&$withDiscount': {
        fontSize: 14,
        fontWeight: 'normal',
        marginRight: '7px',
        marginTop: '0',
        textDecoration: 'line-through',
      },
    },
    productFullSizes: {
      alignItems: 'center',
      color: ColorsPalette.secondary,
      display: 'flex',
      flexDirection: 'row',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '160%',
    },
    productFullTopAction: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: 12,
      top: '3%',
      width: 'calc(100% - 24px)',
    },
    productLink: {
      textDecoration: 'none',
    },
    stock: {
      marginLeft: 14,
      textDecoration: 'none',
    },
    stockLow: {
      color: ColorsPalette.outletDark,
    },
    stockOut: {
      color: ColorsPalette.salesDark,
    },
    tag: {
      '&$discountTag, &$outletTag': {
        background: 'rgba(255, 255, 255, 0.81)',
        color: ColorsPalette.salesDark,
        fontFamily: FontCollection.inter,
        fontSize: '14px',
        fontWeight: 'normal',
        marginRight: 'auto',
      },
      '&$freshTag': {},
      '&$outletTag': {
        color: ColorsPalette.primary,
      },
      alignItems: 'center',
      background: 'rgba(255, 255, 255, 0.81)',
      borderRadius: '100px',
      color: ColorsPalette.primary,
      display: 'flex',
      fontFamily: FontCollection.raleway,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      height: '24px',
      justifyContent: 'center',
      lineHeight: '160%',
      padding: '0 8px',
      textAlign: 'center',
    },
    tagBlur: {
      [theme.breakpoints.up('md')]: {
        backdropFilter: 'blur(16px)',
      },
    },
    withDiscount: {},
  }),
);
