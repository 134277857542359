import React from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import { IProductColor, IProductColorMini, IProductInList } from '@interfaces/product';
import ColorRound from '../../pages/cart/components/ColorRound';

interface IProductFullResponsiveDataColorsProps {
  item: IProductInList;
  productId?: number;
}

const ProductFullResponsiveDataColors: React.FunctionComponent<IProductFullResponsiveDataColorsProps> = ({
  item,
  productId,
}) => {

  const findCurrentColor = (color: IProductColor | IProductColorMini) => String(color.productId) === String(productId);
  const filterNotCurrentColors = (color: IProductColor) => !findCurrentColor(color);
  const filterNotCurrentColorsMini = (color: IProductColorMini) => !findCurrentColor(color);

  const mapColor = (color: { code?: string[], image?: string }, key: number) => <ColorRound key={`uniqColor${key}`}
                                                                                            color={color} />;

  const colorsList = item.miniColors?.length
    ? item.miniColors.filter(filterNotCurrentColorsMini).map((color) => ({
      code: color.colorCodes,
      image: color.colorPictureUrl,
    }))
    : item.colors.filter(filterNotCurrentColors).map((color) => ({ code: color.code, image: color.colorPictureUrl }));
  const colors = colorsList.flat();
  const uniqColorsLength = colors.length;


  return uniqColorsLength > 0 ? (<ErrorBoundary>
    {uniqColorsLength > 4
      ? <>{colors.slice(0, 4).map(mapColor)} &nbsp; +{uniqColorsLength - 4}</>
      : colors.map(mapColor)}
  </ErrorBoundary>) : null;
};

export default ProductFullResponsiveDataColors;
