import classNames from 'classnames';
import React, { ReactNode } from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
import ProductFullResponsiveDataColors from '@components/common/ProductFullResponsive/ProductDataColors';
import ProductFullResponsiveDataPrice from '@components/common/ProductFullResponsive/ProductDataPrice';
import ProductFullResponsiveDataSizes from '@components/common/ProductFullResponsive/ProductDataSizes';
import ProductImage from '@components/common/ProductFullResponsive/ProductImage';
import Price30Days
  from '@components/pages/product/product_detail/detail-area/DetailAreaTitlePriceContainer/price30Days';
import { IProductColor, IProductColorMini, IProductInList } from '@interfaces/product';
import Skeleton from '@mui/material/Skeleton';
import { isProductGiftCard, isProductNotAvailable } from '@utils/product';

import styles from './styles';

interface IProductFullProps {
  item: IProductInList;
  productId?: number;
  rightAction?: ReactNode;
  sizeId?: number;
  withLike?: boolean;
}

const ProductFullResponsiveData: React.FunctionComponent<IProductFullProps> = ({
  item,
  productId,
  rightAction,
  withLike = true,
  sizeId,
}) => {
  const classes = styles();

  const findCurrentColor = (color: IProductColor | IProductColorMini) => String(color.productId) === String(productId);
  const currentColor: IProductColor | undefined = productId ? item.colors.find(findCurrentColor) : item.colors[0];
  const isGiftCard: boolean = isProductGiftCard(item);
  const isNotAvailable = isProductNotAvailable(item);

  return (
    <ErrorBoundary>
      <ProductImage product={item}
                    productId={productId}
                    rightAction={rightAction}
                    sizeId={sizeId}
                    withLike={withLike}
                    isNotAvailable={isNotAvailable} />
      <div className={classes.productFullNameItem}>
        {item?.brand?.brand || <Skeleton animation={false} width={'30%'} />}
      </div>
      <div className={classes.productFullNameItem}>
        {item?.modelType || <Skeleton animation={false} width={'50%'} />}
      </div>
      <ProductFullResponsiveDataPrice item={item} productId={productId} />
      {!isGiftCard && (
        <div className={classNames(classes.productFullCases, classes.productFullSizes)}>
          <ProductFullResponsiveDataSizes item={item} />
          <ProductFullResponsiveDataColors item={item} productId={productId} />
        </div>
      )}
      <Price30Days color={currentColor} isList={true} />
    </ErrorBoundary>
  );
};

export default ProductFullResponsiveData;
